import React from "react";
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { openContactDialog } from '../../../store/actions/dialogActions';
import backgroundImage1 from "../../../images/home_hero_bg.svg";
import backgroundImage2 from "../../../images/radio-wave-bw.png";
import '../../Pricing/styles.css';

const HomePage3Content = ({ openContactDialog }) => {

  const dispatch = useDispatch();

  return (
    <div className="pricing-content">
      <div className="pricing-container">
        <div className="pricing-tile">
          <div className="plan-header">
            <h2>Base Plan</h2>
            <span className="beta-tag">Alpha</span>
          </div>
          <ul>
            <li>Dynamic Geo-Tagged RF data</li>
            <li>pay per API call</li>
            <li>Options to choose specific data</li>
            <li>Email support</li>
          </ul>
          <button onClick={() => dispatch(openContactDialog)}>Choose Plan</button>
        </div>
        <div className="pricing-tile">
          <div className="plan-header">
            <h2>Enterprise / Custom</h2>
          </div>
          <ul>
            <li>Custom RF mapping</li>
            <li>Advanced insight</li>
            <li>Priority support</li>
          </ul>
          <button onClick={() => dispatch(openContactDialog)}>Contact Us</button>
        </div>
      </div>


      {/* Background image 1 without opacity */}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${backgroundImage1})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          opacity: 0.5,
          zIndex: 1, // Behind the second background
        }}
      ></div>

      {/* Background image 2 with reduced opacity */}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${backgroundImage2})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          opacity: 0.3,
          zIndex: 2, // Above the first background
        }}
      ></div>

    </div>
  );
}

const mapDispatchToProps = {
  openContactDialog,
};

export default connect(null, mapDispatchToProps)(HomePage3Content);